module.exports = {
    seo: {
        defaultTitle: "Marmelab - Atelier d'innovation digitale",
        defaultDescription:
            "Marmelab, atelier d’innovation digitale, développe vos projets d'innovation web et mobile avec agilité, pragmatisme et gourmandise.",
        blogTitle: 'Le blog de marmelab',
        blogDescription:
            "Articles, tutorials, project feedbacks: read all about marmelab's activity on Agile project management, Lean startup, Angular and Backbone development, Gaudi and Docker, Symfony2, PHPCR, and much more!",
        jobTitle: 'Travaillez chez Marmelab',
        jobDescription:
            "Marmelab, atelier d’innovation digitale, développe vos projets d'innovation web et mobile avec agilité, pragmatisme et gourmandise.",
        showcasesTitle: 'Marmelab - Nos réalisations',
        showcasesDescription:
            "Marmelab, atelier d’innovation digitale, développe vos projets d'innovation web et mobile avec agilité, pragmatisme et gourmandise.",
        valuesTitle: 'Marmelab - Nos valeurs',
        valuesDescription:
            "Nos choix pour rendre le monde meilleur grace à l'innovation numérique",
        organizationTitle: 'Marmelab - Nos équipes',
        organizationDescription: "L'organisation des équipes de Marmelab",
    },
    home: {
        tagline: `
        Notre mission : rendre le monde meilleur grâce au numérique. Nous mettons notre expertise technique et nos pilotes agiles
        au service de vos projets d'innovation web et mobile.`,
    },
    menu: {
        home: 'Accueil',
        showcase: 'Réalisations',
        jobs: 'Jobs',
        blog: 'Blog',
        values: 'Valeurs',
        menu: 'Menu',
    },
    title: {
        blog: 'Marmelab Blog',
    },
    aboutus: {
        title: 'Nos activités',
        learn_more: 'En savoir plus',
        contact_us: 'Contactez-nous',

        innovation: 'Développement web & mobile',
        innovationContent: `<p>
        Nous prenons en charge la réalisation des produits de bout en bout,
        dans une démarche itérative inspirée du Lean Startup, 
        y compris dans des environnements très contraints (sécurité, performance, disponibilité).
        </p>
        <p>
        Pour remettre la création de valeur au cœur du développement, nos équipes de développeurs full-stack
        s’appuient sur les best practices agiles et le meilleur des technologies open-source
        (React.js, Node.js, GraphQL, Symfony, PostgreSQL, NoSQL).
        </p>`,
        innovationExpandableContent1: {
            title: 'Démarrage rapide',
            content: `<p>La rapidité est clé dans l'innovation. Pour mettre toutes les chances de votre côté,
            nous nous engageons à démarrer le développement 2 semaines après le lancement du projet.</p>
            <p>Durant cette "Itération zéro", nous appliquons une démarche très outillée
            visant à comprendre le besoin, définir les critères de succès, identifier les profils des utilisateurs finaux,
            dresser la vision de la cible, dessiner l'architecture technique, ordonner les priorités,
            et préparer la première itération.</p>`,
        },
        innovationExpandableContent2: {
            title: 'Développement itératif',
            content: `<p>
            Nous mettons à disposition des équipes de développeurs et d'architectes experts, par "itérations".
            Une itération consiste en : </p>
            <ul>
            <li>deux développeurs dédiés au projet</li>
            <li>l'assistance d'un facilitateur à mi-temps</li>
            <li>l'apport d'un expert technique à raison de 1 jour par itération</li>
            <li>une durée de 2 semaines</li>
            <li>un portail projet clés en mains</li>
            <li>une plate-forme de tests</li>
            </ul>
            <p>Nous travaillons depuis nos locaux, et nous nous nous déplaçons pour les démos en fin d’itération.</p>
            <p>Nous nous mettons d'accord en début de prestation sur le nombre d'itérations prévues
            afin de garantir le budget et la même équipe sur toute la durée du projet.</p>`,
        },
        innovationExpandableContent3: {
            title: 'Standards de qualité professionnels',
            content: `<p>Nos équipes sont rompues aux exigences de qualité, de disponibilité et de sécurité des applications professionnelles.
            Intégration continue, sondes de sécurité, tests de montée en charge, tableaux de bord de supervision métier
            en temps réel viennent en standard.</p>
            <p>Quant à la performance, nous avons déjà mené des projets pour plusieurs
            centaines de milliers d'utilisateurs simultanés en dynamique avec succès.</p>`,
        },
        innovationExpandableContent4: {
            title: 'Encadrement contractuel',
            content: `<p>
            Enfin, l'encadrement contractuel de la prestation de marmelab garantit que cette 
            <strong>approche "centre de service"</strong>, à mi-chemin entre le forfait et la régie,
            aboutit dans tous les cas à une collaboration efficace.</p>
            <p>Pour en savoir plus, vous pouvez consulter nos références clients
            sur la page <a href="https://marmelab.com/fr/showcase">Réalisations</a></p>`,
        },

        development: 'Framework B2B',
        developmentContent: `
        <p>Depuis 5 ans, nous éditons et maintenons <a href="https://marmelab.com/react-admin">React-admin</a>, 
        une solution low-code open-source qui réduit les coûts de développement
        de dizaines de milliers d’entreprises à travers le monde.</p>
        <p>Depuis 2020, React-admin est également disponible en version Entreprise, sur le même modèle "Open-Core"
        que Redis ou MySQL. Cette offre est aujourd’hui utilisée par plus de 200 entreprises qui profitent 
        d'un support professionnel pour leurs développeurs, ainsi que des modules privés maintenus par Marmelab.</p> `,
        developmentExpandableContent1: {
            title: 'Prototypage rapide',
            content: `<p>React-admin permet de développer une application d'administration
            en quelques minutes, en construisant les interfaces automatiquement sur la base du modèle de données.</p>
            <p>Les développeurs ont alors toute latitude pour personnaliser le code généré, de façon à coller au plus près
            aux besoins des utilisateurs finaux.</p>`,
        },
        developmentExpandableContent2: {
            title: 'Une solution full-stack',
            content: `<p>Tous les besoins de base des applications B2B sont couverts : de la gestion de rôles à l'internationalisation,
            en passant par la possibilité de personnaliser l'interface de fond en comble. React-admin permet aux développeurs
            de se concentrer sur la logique métier.</p>
            <p>C'est un framework est à la fois complet et complètement débrayable, qui ne ralentit jamais le développement.</p>`,
        },
        developmentExpandableContent3: {
            title: 'Une expérience développeur acclamée',
            content: `<p>Une documentation complète, des applications d'exemple sophistiquées,
            une validation du code à la compilation grâce à TypeScript, une architecture modulaire,
            un code clair et documenté... Les développeurs qui découvrent react-admin sont productifs et enchantés.</p>
            <p>C'est que react-admin n'est pas notre première incursion dans l'open-source... 
            Jetez un oeil à notre <a href="https://github.com/marmelab">GitHub</a> : nous échangeons constamment avec les développeurs du monde entier.-</p>`,
        },
        developmentExpandableContent4: {
            title: 'Support Professionnel',
            content: `<p>Les grands comptes comme les startups choisissent react-admin parce qu'ils savent
            qu'une entreprise solide en assure le sponsoring et le support. Le version Entreprise permet d'ailleurs à ce projet
            d'être autosuffisant, ce qui garantit sa pérennité et sa stabilité.</p>`,
        },

        consulting: "Logiciel d'écoconception numérique",
        consultingContent: `
        <p>Nous éditons <a href="https://greenframe.io">GreenFrame</a>, une solution SaaS qui mesure en continu
        les émissions de CO2 d'une application web.</p>
        <p>En contribuant à l'outillage nécessaire à l'amélioration des pratiques des développeurs, GreenFrame assiste
        les entreprises dans leur démarche de sobriété numérique. Elles peuvent ainsi réduire l'impact climatique
        de leurs produits numériques, qui représente déjà 4% des émissions globales de gaz à effet de serre. </p>
        <p>GreenFrame est la preuve de notre engagement concret dans la lutte contre le réchauffement climatique.</p> 
        `,
        consultingExpandableContent1: {
            title: 'De solides bases scientifiques',
            content: `<p>GreenFrame s'appuie sur un modèle scientifique élaboré en collaboration avec le CNRS.
            Du navigateur au serveur de bases de données, en passant par le réseau et les serveurs web,
            GreenFrame mesure toutes les émissions causées par un scénario d'usage de l'application.</p>
            <p>Plus de 18 mois ont été nécessaires à l'élaboration du moteur de mesure, afin qu'il produise des
            résultats précis, reproductibles, et exploitables à des fins d'optimisation.</p>`,
        },
        consultingExpandableContent2: {
            title: "L'assurance climat",
            content: `<p>En s'exécutant dans l'intégration continue (GitHub Actions, Gitlab CI, Travis, etc..),
            GreenFrame détecte lorsqu'un changement de code cause un accroissement des émissions, et alerte le développeur
            responsable du changement directement dans son outil de gestion de configuration (GitHub).</p>
            <p>GreenFrame permet de suivre au cours du temps les gains obtenus par une démarche de sobriété numérique,
            de valider ou d'invalider l'impact climatique d'un changement applicatif ou technique.</p>`,
        },
        consultingExpandableContent3: {
            title: "Un bénéfice pour l'entreprise et ses clients",
            content: `<p>En aidant à améliorer l'image de marque de ses clients, GreenFrame participe à leur démarche RSE,
            et leur permet de toucher de nouveaux prospects et candidats.</p>
            <p>Sans compter le ROI gagné par l'optimisation des ressources serveur, et le regain de la satisfaction client !</p>`,
        },
    },
    viewMoreFromBlog: "Plus d'articles sur le blog",
    wearemaker: {
        title: 'Nous sommes des faiseurs',
        tagline:
            'Nous développons des produits numériques innovants pour des grands comptes et des startups',
        text: `
            Nos valeurs: l’amour du travail bien fait, le pragmatisme, le partage de la connaissance, la curiosité, et le plaisir au quotidien.
            Nous sommes une petite structure, donc tout le monde compte.`,
        offices: 'Nos bureaux',
        joinus: 'Rejoignez-nous !',
    },
    viewUsOnTwitter: 'Suivez notre actualité sur twitter',
    viewUsOnGithub: 'Suivez nos projets sur github',
    showcases: {
        title: 'Cas client',
        readmore: 'Lire',
        view_site: 'En savoir plus',
        view_project: 'Voir le code',
        project: 'Projet',
        lab_work: 'Le Lab',
        product: 'Produit',
    },
    readMore: 'Lire',
    latestPosts: 'Derniers articles',
    latestPostsBy: 'Derniers articles par %{author}',
    noPostsBy: "%{author} n'a pas encore publié d'article pour le moment.",
    olderPosts: 'Articles plus anciens',
    customers: {
        title: 'Qui sont nos clients ?',
    },
    call_to_action: 'Travaillons ensemble sur votre prochain projet !',
    we_are_marmelab:
        'Marmelab est un atelier d’innovation digitale. Nous développons des produits numériques innovants pour des grands comptes et des startups depuis 2013. Nous sommes aussi les créateurs de react-admin et greenframe.io',
    showcase: {
        title: "Nos clients ont un besoin en commun : l'innovation",
        tagline: 'Grands comptes et startups, travaillons ensemble !',
        our_work: 'Zoom sur...',
        inside_our_lab: 'Dans le lab',
        inside_our_lab_text_1:
            'Notre R&D est souvent open-source, et totalise plus de 80 000 stars sur GitHub.',
        inside_our_lab_text_2:
            'Pour en savoir plus sur nos dernières innovations, rendez-vous sur <a href="https://github.com/marmelab">github.com/marmelab</a>.',
    },
    values: {
        preTitle: 'Notre vision :',
        title: `Rendre le monde meilleur grace à l'innovation numérique`,
        tagline: `La tech au service du développement durable :<br />
        c'est notre notre engagement,
        <a href="https://www.bcorporation.net/en-us/find-a-b-corp/company/marmelab/">certifié B Corp</a>.`,
        intro: `Cette vision nous guide dans tous les choix que nous faisons au quotidien.
        Voici comment elle s'applique dans différents domaines.`,
        themes: {
            suppliers: 'Fournisseurs',
            mobility: 'Mobilité',
            office: 'Vie au bureau',
            sharing: 'Partage',
            workers: 'Equipes',
            products: 'Produits',
            management: 'Gestion',
        },
        readmore: 'Découvrir nos valeurs',
    },
    organization: {
        preTitle: 'Organisation :',
        title: `La répartition des équipes de Marmelab`,
        link: `Voir sa page`,
        dev: 'Développeur',
        facilitator: 'Facilitateur',
        ceo: 'Président',
        sale: 'Commercial',
        manyThings: 'Office manager',
    },
    aboutAuthors: "À propos de l'auteur |||| À propos des auteurs",
    discoverMore: 'Commentaires',
    jobs: {
        why: 'Pourquoi marmelab&nbsp;?',
        presentation: `<p>
            Ici, vous apprendrez plus que vous n'avez jamais appris.
        </p>
        <p>
            Peu importe d'où vous venez, vous aurez l'opportunité de repousser vos limites et de vous améliorer chaque jour, entouré(e) de personnes passionnées prêtes à vous aider en toutes circonstances.
        </p>
        <p>
            Vous travaillerez sur des produits exaltants : ceux que nos clients imaginent pour faire bouger les lignes. Car c'est notre conviction : notre travail doit <a href="https://marmelab.com/fr/values/">rendre le monde meilleur grace à l'innovation numérique</a>.
        </p>`,
        values: {
            creativity: {
                name: 'Créativité',
            },
            pragmatism: {
                name: 'Pragmatisme',
            },
            'knowledge-sharing': {
                name: 'Partage de connaissance',
            },
            kindness: {
                name: 'Bienveillance',
            },
            craving: {
                name: 'Gourmandise',
            },
        },
        team: {
            life: 'La vie à marmelab',
            content:
                'Qui sont vos prochains collègues ? Vont-ils vous manger ? La réponse, en toute transparence, est sur Internet.',
        },
        projects: {
            title: 'Les projets',
            intro: `
            <p>
                Parce que nous souhaitons tirer le meilleur parti de nos connaissances et méthodes de travail, tous les projets que nous prenons sont des projets d'innovation.
            </p>
            <p>
                Les incertitudes peuvent être techniques, d'usage ou de business model, peu importe : nous avons la culture du challenge.
            </p>
            `,
            cloud1859: {
                name: '1859 Cloud',
                description:
                    'Valider un business model FinTech audacieux en développant une application mêlant anonymat et gamification',
            },
            'react-admin': {
                name: 'React Admin',
                description:
                    'Capitaliser sur notre expertise des administrations en créant un framework générique basé sur react, redux, redux-saga, et material ui',
            },
            inist: {
                name: 'Lodex',
                description:
                    "Améliorer l'accès à la documentation scientifique pour les chercheurs en développant un CMS Web Sémantique",
            },
            arte: {
                name: 'Arte.tv',
                description:
                    'Le site de replay phare de la culture franco-allemande est développé en partie par Marmelab. Nous développons cette plateforme cumulant React.js, Node.js et GraphQL.',
            },
            amnesty: {
                name: 'Amnesty International',
                description:
                    "Inventer un nouveau mode de militantisme, à mi-chemin entre la pétition et l'engagement en groupe, grâce au numérique : mission impossible ? Nous avons relevé le défi.",
            },
            link: 'Voir le projet',
        },
        perks: {
            title: 'Avantages',
            hackday: {
                name: 'Hack days',
                description:
                    "2 journées off par mois réservées à l'apprentissage et l'expérimentation",
            },
            conferences: {
                name: 'Conférences',
                description:
                    'Plusieurs conférences par an, en tant que participant ou orateur',
            },
            laptop: {
                name: 'La machine de votre choix',
                description:
                    "Vous choisissez l'outil avec lequel vous travaillerez tous les jours",
            },
            'open-source': {
                name: 'Open Source',
                description:
                    'Nous participons activement aux projets open source',
            },
            salary: {
                name: 'Salaire compétitif',
                description:
                    'Nous savons mettre le prix pour attirer des talents',
            },
            coaching: {
                name: 'Coaching',
                description: 'Bénéficiez de conseil de mentors expérimentés',
            },
            homeOffice: {
                name: 'Télétravail',
                description: 'Travaillez au bureau ou de chez vous',
            },
        },
        process: {
            title: 'Notre process de recrutement',
            contact: {
                name: 'Rentrez en contact',
                description:
                    'Envoyez-nous CV et motivation par mail ou passez directement nous voir au 4 rue Girardet à Nancy.',
            },
            'technical-review': {
                name: 'Passez un entretien technique',
                description:
                    'Notre CEO vous reçoit et vous fait passer un entretien technique pour évaluer vos compétences.',
            },
            'team-fit-review': {
                name: "Rencontrez l'équipe",
                description:
                    "Parce que vous allez travailler avec tout marmelab et qu'on veut que ça colle, on vous invite à partager un café ou une bière tous ensemble pour apprendre à se connaître.",
            },
            contract: 'Signature de votre contrat',
            integration: {
                name: 'Intégrez-vous !',
                description:
                    'Pour que vous puissiez apprendre en expérimentant, on vous a préparé des challenges à relever pour les 5 premières semaines.',
                link: "Découvrez notre process d'intégration",
            },
        },
        apply: {
            title: 'Vous vous voyez chez marmelab&nbsp;?',
            jobs:
                'Voici les différents postes auxquels nous recrutons. Laissez-nous vous raconter ce en quoi ils consistent.',
            developer: { name: 'Développeur' },
            facilitator: { name: 'Facilitateur' },
            link: 'Découvrir',
            other: `
            <p>Venez avec vos idées, votre propre expertise, vos expériences et votre envie de partage.</p>`,
        },
        developer: {
            title: 'Être développeur chez marmelab',
            intro: `
            <p>
                Chez marmelab, le rôle du développeur n'est pas que de coder, mais plutôt de transformer les idées de nos clients en véritable produit. Pour ce faire, il s'appuie sur des technologies de pointe, un processus agile, une communication permanente avec le client,  et une responsabilité complète de la partie technique du produit.
            </p>
            `,
            stack: {
                title: 'Une stack à la pointe',
                intro: `
                <p>
                    Nous choisissons toujours l'outil le plus adapté au besoin de chaque client. Et même si nos technologies de prédilection sont aujourd'hui orientées JavaScript full-stack, nous avons également des projets en Symfony, Python et Go.
                </p>
                <p>
                    Nous prenons au sérieux les évolutions du web : nous réservons un temps dédié à la veille afin de tester les nouvelles approches, les nouveaux langages, ou les nouvelles librairies les plus prometteuses. Nous n'hésitons d'ailleurs pas à utiliser des technologies récentes dans nos projets en production, quand elles apportent un vrai bénéfice client. Ainsi, nous avons intégré <a href="/blog/2013/02/13/lancement-de-cloudscreener-le-comparateur-d-offre-cloud-pour-les-pme.html">Node.js en 2012</a>, <a href="/blog/2013/07/01/building-sophisticated-webapps-for-mobile-a-bumpy-ride.html">d3.js en 2013</a>, <a href="/blog/2015/05/18/setup-webpack-for-es6-react-application-with-sass.html">React.js en 2015</a>, <a href="/blog/2016/12/16/tobaccobot.html">Serverless en 2016</a>, <a href="/en/blog/#graphql">GraphQL en 2017</a>, <a href="/blog/2018/10/18/react-admin-2-4.html">TypeScript en 2018</a>. et ça continue !
                </p>
                <p>
                    Ici les passionnés de technique web et mobile ont de quoi trouver leur bonheur.
                </p>
                `,
                'react-redux': {
                    name: 'React',
                },
                nodejs: {
                    name: 'Node.js',
                },
                graphql: {
                    name: 'GraphQL',
                },
                postgresql: {
                    name: 'PostgreSQL',
                },
                aws: {
                    name: 'AWS',
                },
            },
            'agility-and-communication': {
                title: "De l'agilité et de la communication",
                intro: `
                <p>
                    Pour livrer des fonctionnalités qui apportent beaucoup de valeur, nous favorisons un contact direct et permanent avec le client et ses équipes métier, fondé sur les méthodes agiles.
                </p>
                <p>
                    Nous organisons les développements en sprints courts, dont le contenu est planifié avec le client toutes les 2 semaines.

                    Chaque nouvelle fonctionnalité est conçue en collaboration avec le client, qui affine sa vision au fur et à mesure que son application se construit. Et les retours des utilisateurs, au travers des métriques métier, nous aident à parvenir plus rapidement à des produits efficaces.
                    De cette façon, nous nous assurons que chaque développement réalisé est pertinent et résoud un problème.
                </p>
                <p>
                    Nous faisons un point rapide chaque matin avec le Product Owner côté client, par visioconférence. A la fin de chaque sprint, nous faisons un bilan en face à face.
                    Grace à ces échanges, nous sommes toujours connectés à la réalité terrain. En plus, nous pouvons faire valoir notre travail en le présentant en direct au client.
                </p>
                `,
            },
            ownership: {
                title: 'Responsabilisation et Autonomie',
                intro: `
                <p>
                    Ici, les développeurs proposent des solutions correspondant aux besoins des clients. Ils choisissent les technologies les plus adaptées en tenant compte du contexte client, avec pragmatisme.
                </p>
                <p>
                    Comme pour tous les développements, ces choix se font en équipe, et sont assumés par tous.
                    Cette autonomie engendre une responsabilisation et une implication forte dans les projets de nos clients. Elle nous permet d'avoir un impact important sur la direction qu'ils prennent.
                </p>
                <p>
                    Nous faisons fréquemment des choix pour nos clients et écrivons nos apprentissages dans notre blog, regardez par vous-même.
                </p>
                `,
                redux: {
                    name: 'Doit-on toujours utiliser redux ?',
                },
                'slider-poll': {
                    name: 'Migration progressive de Angular à React',
                },
                accessibility: {
                    name: "Comment automatiser des tests d'accessibilité ?",
                },
            },
            lookout: {
                title: 'Veille technologique',
                intro: `
                <p>
                    Marmelab se tient a jour des technologies du web et du mobile.
                    Et pour ça, nous allons régulièrement assister à des conférences sur divers sujets, cela fait partie de notre travail.
                </p>
                <p>
                    L'objectif est de s'informer et d'en tirer quelque chose d'utile, généralement sous la forme d'un article publié pour la communauté.
                </p>
                <p>
                    Nous intervenons aussi régulièrement comme conférenciers. Si un sujet vous tient à coeur, marmelab vous encouragera à en parler en public.
                </p>
                `,
                conferences: {
                    blend: {
                        name: 'Blend Web Mix',
                    },
                    devfest: {
                        name: 'DevFest Nantes',
                    },
                    dotai: {
                        name: 'Dot AI',
                    },
                },
                intro2: `
                <p>
                    Un jour par sprint, nous organisons un <i>hackday</i>.
                    Il s'agit de choisir un sujet technique, sans lien avec le projet sur lequel on est affecté, et de passer la journée à travailler dessus.
                </p>
                <p>
                    Cela peut être un nouveau langage à tester, un outil utile à développer, une méthodologie à étudier.
                    L'objectif est d'apprendre quelque chose, et bien sûr de pouvoir en faire profiter les autres, au travers d'un article ou d'un projet open-source par exemple.
                </p>
                `,
                hackdays: {
                    detox: {
                        name: 'Detox : un framework de test',
                    },
                    'memory-leaks': {
                        name: 'Comment chasser les fuites mémoire ?',
                    },
                    'functional-programming': {
                        name: "Qu'est-ce que la programmation fonctionnelle ?",
                    },
                },
            },
            apply: {
                title: 'Vous vous voyez développeur marmelab&nbsp;?',
                intro: `
                <p>
                    La principale qualité que nous cherchons chez un développeur est l’amour du travail bien fait. Peu importe le langage de programmation que vous maîtrisez. Vous apprendrez à nos côtés les technos du moment, et la bonne manière de les mettre en oeuvre.
                </p>
                <p>
                    Nous recherchons des développeurs qui, au-delà de l'expertise d'un langage en particulier, ont acquis l'expérience de la programmation web en général, et sauront transposer cette expérience dans d'autres langages.
                </p>

                <p>Attention, nous recherchons des développeurs confirmés, avec <strong>au moins 5 ans d'expérience</strong>. Vous maitrisez notamment:</p>

                <ul>
                    <li>Un langage de programmation (TypeScript, PHP8, C#, Java, Ruby, Python)</li>
                    <li>Un framework MVC (Nest.js, Symfony, .Net, Spring, RoR, Django), utilisé en production sur un projet d'envergure</li>
                    <li>Les architectures API-centric (REST, GraphQL, protobuf)</li>
                    <li>Les design patterns (PoEE, DDD, hexagonal architectures, clean code)</li>
                    <li>Les tests automatisés (unitaires, d'intégration et end-to-end)</li>
                    <li>Les aspects Front (HTML, CSS, DOM, data fetching, webperf, animations, accessibilité)</li>
                    <li>Une base de données relationnelles (PostgreSQL, MySQL, MSSQL, Oracle)</li>
                    <li>Une base NoSQL (ElasticSearch, Redis, MongoDB, Cassandra)</li>
                    <li>Linux et Docker</li>
                    <li>L'optimisation de performance (profiling, cycle de vie d'une page web, gestion mémoire)</li>
                    <li>Le Software craftsmanship (TDD, Refactoring, CI/CI, code reviews, propriété collective du code)</li>
                    <li>La résolution de problèmes complexes (debug, algorithmes, structures de données)</li>
                    <li>Les méthodes agiles (SCRUM / KANBAN / Lean Startup)
                    <li>Le travail en équipe</li>
                    <li>Le français et l'anglais</li>
                </li></ul>
                `,
                outro: 'Prêt à relever le défi ?',
            },
        },
        facilitator: {
            title: 'Être facilitateur à marmelab',
            intro: `
            <p>
                À marmelab, le rôle du facilitateur est de booster une équipe produit pour la rendre plus efficace, en prenant en compte tous les aspects humains d'un tel challenge. Pour ce faire, il s'appuie sur des méthodes venues de l'entreprenariat, de l'industrie, du développement logiciel et du coaching.
            </p>
            <p>Le facilitateur est un poste très versatile remplissant différents rôles.</p>
            `,
            inception: {
                title: 'Démarrage projet',
                intro: `
                <p>
                    Le démarrage projet consiste à préparer tout ce qui est nécessaire pour que les développeurs puissent entamer leur premier sprint, et dresser une vision de ce qui peut arriver pour les suivants. Nous avons décrit en détail notre méthode d'initialisation de projet dans <a href="/blog/2017/01/17/initialisation-des-projets.html"/>cet article</a>.
                </p>
                <p>
                    Cette démarche se déroule en équipe, avec les acteurs métiers, et les utilisateurs finaux si possible, et tire partie de plusieurs méthodes qu'il convient de choisir en fonction du contexte.
                </p>
                `,
                'lean-startup': {
                    name: 'Lean Startup',
                    description:
                        "Tester la viabilité d'un produit ou business model en itérant sur les hypothèses à plus fort impact",
                },
                'design-thinking': {
                    name: 'Design Thinking',
                    description:
                        'Générer des idées centrées utilisateur : Empathize, Define, Ideate, Prototype and Test',
                },
                scrum: {
                    name: 'Scrum',
                    description:
                        'Transformer les idées en story maps, puis en user stories réalisables par les développeurs',
                },
            },
            'project-management': {
                title: 'Gestion de projet',
                intro: `
                <p>
                    Dès le démarrage des développements, le facilitateur prend un rôle semblable au Scrum Master. Côté produit, on utilise les mêmes outils que pour le démarrage projet, afin de préparer le backlog des sprints suivants.
                </p>
                <p>
                    Le facilitateur a pour mission d'améliorer l'efficacité de l'équipe toute entière, en animant les rituels agiles (mélée, démonstration, rétrospective, planning), en utilisant le framework Scrum, mais surtout en faisant preuve de beaucoup de pragmatisme et d'empathie.
                </p>
                `,
                'identify-impediments': {
                    name: 'Aider à identifier les obstacles',
                    description:
                        'Faciliter les rétrospectives en mettant en place un contexte de confiance',
                },
                'self-organization': {
                    name: "Promouvoir l'auto organisation",
                    description:
                        "Pousser l'équipe à communiquer et à travailler ensemble par instinct",
                },
                delivery: {
                    name: 'Encourager à livrer fréquemment',
                    description:
                        "Réduire le gaspillage à l'aide de cycles de feedbacks courts",
                },
                value: {
                    name: 'Mettre le focus sur la valeur',
                    description:
                        "Positionner l'utilisateur final au centre des discussions",
                },
            },
            'continuous-improvement': {
                title: 'Amélioration continue',
                intro: `
                <p>
                    En dehors des projets de nos clients, le facilitateur doit, au même titre que pour une équipe projet, faciliter l'organisation globale de l'entreprise.
                </p>
                <p>
                    On compare par exemple fréquemment les problèmes rencontrés sur les différents projets et l'efficacité des solutions mises en place. On organise des ateliers de partage de connaissances, avec des forums ouverts, ou plus ciblés.
                    Comme pour les développeurs, il est important d'explorer de nouvelles méthodes, de s'inspirer de ses pairs et de partager ses avancées.
                </p>
                <p>
                    Par ailleurs, dans les problèmes que l'entreprise rencontre au quotidien, le facilitateur mobilise l'intelligence collective pour trouver des solutions innovantes. De l'organisation des hack days à l'amélioration du processus d'onboarding, tout est sujet à amélioration.
                </p>
                `,
                'knowledge-sharing': {
                    name: 'Organiser le partage de connaissance',
                    description:
                        'Proposer des "BBL", des journées forums ouvert, des discussions plus privées',
                },
                lookout: {
                    name: 'Veille méthodologique',
                    description:
                        "Lire, se rendre à des conférences, lire, discuter avec ses pairs, le tout couplé avec de l'expérimentation en projet",
                },
            },
            apply: {
                title: 'Vous vous voyez facilitateur&nbsp;?',
                intro:
                    "Le job nécessite de s'intéresser à un large panel de domaines, voici les compétences que nous pensons essentielles:",
                softSkills: {
                    title: 'Savoir-être',
                    content: `
                    <ul>
                        <li>Bon communiquant</li>
                        <li>Empathique</li>
                        <li>Pragmatique</li>
                        <li>Curieux</li>
                        <li>Enthousiaste</li>
                        <li>Joueur</li>
                    </ul>
                    `,
                },
                hardSkills: {
                    title: 'Savoir-faire',
                    content: `
                    <ul>
                        <li>Connaissance approfondie de l'agilité projet et produit</li>
                        <li>Scrum, Kanban, Lean Startup</li>
                        <li>Savoir penser "user-centric"</li>
                        <li>Expérience du fonctionnement d'un service web à fort traffic</li>
                        <li>Connaissance profonde du métier de développeur (un passé de développeur web est idéal)</li>
                        <li>Notions d'ergonomie des interfaces</li>
                    </ul>
                    `,
                },
                outro: 'Prêt à relever le défi ?',
            },
        },
    },
    footer: {
        copyrights: 'Tous droits réservés.',
        twitter: 'Compte Twitter de Marmelab',
        github: 'Compte Github de Marmelab',
        linkedin: 'Compte LinkedIn de Marmelab',
    },
    locale: 'FR',
    setLocale: 'Passer en langue %{code}',
    share: {
        text: 'Vous avez aimé cet article ? Partagez-le !',
        facebook: 'Partager sur Facebook',
        twitter: 'Partager sur Twitter',
        linkedIn: 'Partager sur LinkedIn',
        more: "Plus d'options",
    },
    author: {
        twitter: 'Compte Twitter de %{author}',
        github: 'Compte Github de %{author}',
    },
    modal: {
        close: 'Fermer la boîte de dialogue',
    },
    legal: {
        title: 'Mentions légales',
        editor: {
            title: 'Editeur',
            company: 'Entreprise',
            capital: 'Capital',
            registrationNumber: 'Immatriculation',
            VATNumber: 'TVA intracommunautaire',
            address: 'Adresse',
            emailAddress: 'Email',
            publishingDirector: 'Directeur de publication',
        },
        host: {
            title: 'Hébergement',
            provider: 'Hébergeur',
            address: 'Adresse',
            phoneNumber: 'Téléphone',
        },
        privacy: {
            title: 'Vie privée',
            content: `
            <p>
                Parce que votre vie privée nous importe, tout le site web est accessible sans avoir à donner vos informations personnelles.
            </p>
            <p>
                Nous utilisons notre propre solution de tracking auto-hébergée pour collecter
                certaines informations brutes comme le nombre de pages vues, le type d'appareil (mobile, bureau, ...) ou le pays.
                Nous ne traçons aucune information personnelle sur nos visiteurs.
                Nous comptons sur vous pour prendre contact avec nous au lieu de vous traquer.
            </p>
            <p>
                Notre solution de commentaires, commento, est <a href="https://commento.io/privacy">"privacy focused"</a>.
            </p>
            <p>
                Nous avons parfois du contenu intégré venant de sites externes pouvant éventuellement vous tracker.
                Ne vous inquiétez pas, nous vous laissons le choix et demandons avant de charger ces contenus.
            </p>
            <p>
                Les seules informations que nous sauvegardons nous aident à nous rappeler de votre langue préférée et vos préférences de chargement de contenu externe.
            </p>
            `,
        },
    },
};
